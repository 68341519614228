<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0" v-if="success_status=='Loading'">

        <!-- logo -->
        <b-link class="brand-logo">
          <logo-dark class="w-50 h-25"/>

          <!--          <h2 class="brand-text text-primary ml-1">-->
          <!--            CloudX-->
          <!--          </h2>-->
        </b-link>
        <b-card-title class="mb-1">
          Validating Your Email 🖐🏻
        </b-card-title>
        <b-card-text class="mb-2">
          Please Wait!
        </b-card-text>
        <div class="text-center">
          <b-spinner
              style="width: 3rem; height: 3rem;"
              class="mr-1"
              variant="primary"
              label="Large Spinner"
          />
        </div>
        <!-- form -->

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
      <b-card class="mb-0" v-else-if="success_status==='valid'">
        <b-link class="brand-logo">
          <logo-dark class="w-50 h-25"/>

          <!--          <h2 class="brand-text text-primary ml-1">-->
          <!--            CloudX-->
          <!--          </h2>-->
        </b-link>

        <b-card-title class="mb-1">
         Reset procedure sent your email! 🤟🏻
        </b-card-title>
        <b-card-text class="mb-2">
          Please Check Your Email and Follow the given instructions!
        </b-card-text>

        <!-- form -->
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>


      <b-card class="mb-0" v-else>
        <b-link class="brand-logo">
          <logo-dark class="w-50 h-25"/>

          <!--          <h2 class="brand-text text-primary ml-1">-->
          <!--            CloudX-->
          <!--          </h2>-->
        </b-link>

        <b-card-title class="mb-1">
          Forgot Password? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Enter your email and we'll send you instructions to reset your password
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
            >
              Send reset link
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import LogoDark from "@core/layouts/components/logo-dark";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    LogoDark,
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      success_status:false,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let payload={
            email:this.userEmail
          }
          this.success_status='Loading'
          this.$store.dispatch('forgotPassword/RequestPasswordReset',payload).then(result=>{
            console.log(result)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: result.status!=='OK'?'Error' :`Success`,
                icon:result.status!=='OK'?'ThumbsDownIcon':'ThumbsUpIcon',
                variant: result.status!=='OK'?'danger':'success',
                text:  result.status!=='OK'?'Email does not exit! Please Enter a valid Email!': 'Email Sent Successfully!',
              },
            })
            if(result.status=='OK'){
              this.success_status='valid'
            }else{
              this.success_status='Invalid'
            }
          })

        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
